import { useEffect, useReducer, useState } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import classNames from "classnames"
import AudioPlayer from "./AudioPlayer"
import VideoPlayer from "./VideoPlayer"
import Resources from "./Resources"
import { Library } from "source/publishing/sermons/Channel"
import { Date } from "source/shared/DatetimeFmt"
import { isChurchCenterApp } from "source/Layout"
import useQueryString from "source/shared/hooks/useQueryString"
import { useWindowDimensions } from "source/shared/contexts/WindowDimensionsProvider"
import { FlipperFeatureEnabled } from "source/shared/flipperFeatures"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import "./styles.css"
import { SuspenseNotes } from "./Notes"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

const VIDEO = "video"
const AUDIO = "audio"

LibraryEpisode.propTypes = {
  audioDownloadLink: PropTypes.string,
  channel: PropTypes.object,
  episode: PropTypes.object,
  episodeResources: PropTypes.array,
  episodeTime: PropTypes.object,
  featuredResource: PropTypes.object,
  joltChannel: PropTypes.object,
  personEpisodeProgress: PropTypes.object,
  series: PropTypes.object,
  speaker: PropTypes.object,
  setAudioDownloadLink: PropTypes.func,
}

export default function LibraryEpisode({
  audioDownloadLink,
  channel,
  episode,
  episodeResources,
  episodeTime,
  featuredResource,
  joltChannel,
  personEpisodeProgress,
  series,
  speaker,
  setAudioDownloadLink,
}) {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )

  const {
    library_streaming_service: libraryStreamingService,
    library_video_url: libraryVideoUrl,
    sermon_audio: sermonAudio,
    streaming_service: streamingService,
    video_url: videoUrl,
  } = episode.attributes
  const [queryParams, setQueryString] = useQueryString({
    media_intent: null,
  })
  const { media_intent: mediaIntent } = queryParams
  const isCCA = isChurchCenterApp()

  const [isFocused, setIsFocused] = useState(true)
  const [playMedia, setPlayMedia] = useState(null)
  const [firstLoad, toggleFirstLoad] = useReducer(() => false, true)
  const shouldOpenInChurchCenterModal = !isCCA
  const { id: channelId } = channel
  const libraryQuery = series
    ? { "where[series_id]": series.id }
    : { "where[channel_id]": channelId }
  const videoProps = libraryVideoUrl
    ? {
        url: libraryVideoUrl,
        streamingService: libraryStreamingService,
      }
    : {
        videoUrl,
        streamingService,
      }
  const hasAudio =
    sermonAudio.attributes.source === "hosted" ||
    sermonAudio.attributes.source === "remote"
  const hasVideo = !!videoUrl || !!libraryVideoUrl
  const [activeTab, setActiveTab] = useState(hasVideo ? VIDEO : AUDIO)
  const { width: winWidth } = useWindowDimensions()
  const isSingleColumnLayout = winWidth < 960

  function setMediaIntent(media) {
    setQueryString({ ...queryParams, media_intent: media })
  }

  function startMedia(media) {
    setActiveTab(media)
    setPlayMedia(media)
    if (!isCCA) {
      setMediaIntent(media)
    }
    toggleFirstLoad()

    if (media === VIDEO && isCCA) {
      stopAppAudio()
    }

    if (media === AUDIO && isCCA) {
      playAppAudio(episode)
    }
  }

  // When navigating between episodes in CCA, prevent episodes
  // down the navigation stack from autoplaying
  useEffect(() => {
    if (isCCA && !firstLoad && !isFocused) {
      setPlayMedia(null)
    }
  }, [isFocused])

  // Handle media intent changes
  useEffect(() => {
    const hasMediaIntent = [AUDIO, VIDEO].includes(mediaIntent)

    // When media intent is available, autoplay that.
    if (hasMediaIntent && (!isCCA || playMedia !== null)) {
      startMedia(mediaIntent)
    }
  }, [mediaIntent])

  // Make setIsFocused available to React Native
  useEffect(() => {
    if (isCCA) window.setIsFocused = setIsFocused
  }, [!!isCCA, setIsFocused])

  return (
    <>
      <div className="mb-3" css={styles.bleedContainer}>
        {activeTab === VIDEO && (
          <>
            {playMedia === VIDEO ? (
              <VideoPlayer
                {...videoProps}
                showControls={true}
                enforceSync={false}
                episodeTime={episodeTime}
                joltChannel={joltChannel}
              />
            ) : (
              <MediaPoster
                artUrl={episode.attributes.library_art_url}
                buttonLabel="Play video"
                showButton={hasVideo}
                onClick={() => startMedia(VIDEO)}
              />
            )}
          </>
        )}
        {activeTab === AUDIO && (
          <>
            {playMedia === AUDIO ? (
              <AudioPlayer
                audioDownloadLink={audioDownloadLink}
                autoplay={mediaIntent === AUDIO}
                episode={episode}
                personEpisodeProgress={personEpisodeProgress}
                setAudioDownloadLink={setAudioDownloadLink}
              />
            ) : (
              <MediaPoster
                artUrl={episode.attributes.library_art_url}
                buttonLabel="Play audio"
                showButton={hasAudio}
                onClick={() => startMedia(AUDIO)}
              />
            )}
          </>
        )}
      </div>
      <div css={styles.container}>
        <div>
          <div className="d-f jc-sb ai-fs mb-2" css={styles.tabs}>
            <header>
              <Heading level={2} text={episode.attributes.title} />
              <p className="mb-0">
                <Date
                  start={episode.attributes.published_to_library_at}
                  style="standard"
                  year={true}
                />
                {ROLLOUT_episode_speakers && speaker && (
                  <span>
                    &nbsp;&bull; {speaker.attributes.first_name}{" "}
                    {speaker.attributes.last_name}
                  </span>
                )}
                {series && (
                  <span>
                    &nbsp;&bull; Series:&nbsp;
                    <a href={series.attributes.church_center_path}>
                      {series.attributes.title}
                    </a>
                  </span>
                )}
              </p>
            </header>
            {hasAudio && hasVideo && (
              <div
                className="tab-items"
                role="tablist"
                aria-label="Watch or Listen to Episode"
              >
                <button
                  role="tab"
                  aria-selected="true"
                  aria-controls="panel-2"
                  id="tab-2"
                  className={classNames("tab fs-4 mr-1 set-focus-invisible", {
                    "tab--active": activeTab === VIDEO,
                  })}
                  onClick={() => startMedia(VIDEO)}
                >
                  Watch
                </button>
                <button
                  role="tab"
                  aria-selected="true"
                  aria-controls="panel-1"
                  id="tab-1"
                  tabIndex="0"
                  className={classNames("tab fs-4 set-focus-invisible", {
                    "tab--active": activeTab === AUDIO,
                  })}
                  onClick={() => startMedia(AUDIO)}
                >
                  Listen
                </button>
              </div>
            )}
          </div>
          <Resources
            css={styles.resources}
            episodeDescription={episode.attributes.description}
            episodeResources={[featuredResource, ...episodeResources].filter(
              Boolean,
            )}
            showFullDescription={!isSingleColumnLayout}
            shouldOpenInChurchCenterModal={shouldOpenInChurchCenterModal}
          />
          <FlipperFeatureEnabled name="ROLLOUT_sermon_notes">
            <SuspenseNotes />
          </FlipperFeatureEnabled>
        </div>
        <div className="d-f fd-c g-2">
          <Library
            excludeId={episode.id}
            queryParams={{
              ...libraryQuery,
              per_page: 3,
              filter: "published_to_library",
              order: "-published_to_library_at",
            }}
          >
            <Subheading
              level={4}
              text={`More from ${
                series ? series.attributes.title : channel.attributes.name
              }`}
            />
          </Library>
        </div>
      </div>
    </>
  )
}

MediaPoster.propTypes = {
  artUrl: PropTypes.string,
  buttonLabel: PropTypes.string,
  showButton: PropTypes.bool,
  onClick: PropTypes.func,
}

function MediaPoster({ artUrl, buttonLabel, showButton, onClick }) {
  return (
    <div
      className="p-r"
      css={{
        paddingBottom: (9 / 16) * 100 + "%",
        backgroundColor: "var(--color-tint8)",
      }}
    >
      <img
        className="d-b p-a t-0 l-0 w-100% h-100%"
        alt="Episode art"
        css={{ objectFit: "cover" }}
        src={artUrl}
      />
      {showButton && (
        <button
          className="p-a btn"
          css={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            textIndent: "150%",
            overflow: "hidden",
            background: "white",
            width: 64,
            height: 64,
            borderRadius: 32,
            padding: 0,
            "&:after": {
              display: "block",
              content: "''",
              width: "50%",
              height: "50%",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              background: "var(--color-static-gray45)",
              maskImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3E%3Cpath d='M5 15L15 8L5 1V15Z'%3E%3C/path%3E%3C/svg%3E")`,
            },
          }}
          onClick={onClick}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  )
}

export function playAppAudio(episode) {
  const { name: remoteUrl, source: audioSource } =
    episode.attributes.sermon_audio.attributes

  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      type: "playAudio",
      episode,
      url: audioSource === "remote" ? remoteUrl : null,
    }),
  )
}

export function stopAppAudio() {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      type: "stopAudio",
    }),
  )
}

const styles = {
  bleedContainer: css`
    width: min(100vw, 900px);
    margin-inline: auto;

    @media (max-width: 600px) {
      margin-bottom: 16px;
    }
  `,
  container: css`
    width: min(100vw - 2rem, 900px);
    margin-inline: auto;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 32px;

    [data-cca-platform="ios"] & {
      width: min(100vw - 40px, 900px);
    }
    [data-cca-platform="android"] & {
      width: min(100vw - 32px, 900px);
    }

    header p {
      font-size: 13px;
    }

    [data-name="episode summary"] {
      padding: 0;
      background: transparent;
    }

    @media (min-width: 960px) {
      grid-template-columns: 2fr 1fr;
    }
  `,
  tabs: css`
    gap: 24px;

    .tab {
      cursor: pointer;
      color: var(--color-tint2);
      background: transparent;
      text-transform: capitalize;
      border: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: 0 12px;
      border-radius: 16px;
    }
    /* TODO: Remove once a fix is provided.
      This temporarily gives minions specificity over the ongoing work in dark mode.
      An issue was raised with R&F about minions not needing greater specificity than a compatible theming system */

    .tab-items {
      display: flex;
      flex-wrap: nowrap;
    }
    .tab--active {
      color: var(--color-static-gray100);
      background-color: var(--color-brand);
    }
  `,
  resources: css`
    p {
      margin-bottom: 24px;
    }
  `,
}
